
@font-face {
    font-family: Rajdhani;
    src: url("../static/fonts/Rajdhani-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: Rajdhani;
    src: url("../static/fonts/Rajdhani-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: Rajdhani;
    src: url("../static/fonts/Rajdhani-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: Rajdhani;
    src: url("../static/fonts/Rajdhani-SemiBold.ttf") format("truetype");
    font-weight: 600;
}